<template>
  <panel>
    <data-table-local
      :columns="_columns"
      :items="creditTransactionDatas"
      :viewAble="false"
      :newAble="false"
      :deleteAble="false"
      :editAble="false"
    />
  </panel>
</template>
<script>
import { mapState } from "vuex"
export default {
  watch: {
    "reload.creditData"() {
      console.log(12)
      this.fetchCreditData()
    },
  },
  async mounted() {
    this.fetchCreditData()
  },
  methods: {
    async fetchCreditData() {
      try {
        const { creditTransactionDatas, creditData } = await this.api.get(
          `/api/logData/adminCreditTransaction/${this.$route.params.user_id}`
        )
        this.creditTransactionDatas = creditTransactionDatas
        this.creditData = creditData
      } catch (error) {
        console.error(error)
        return ""
      }
    },
  },
  data() {
    return {
      creditTransactionDatas: "",
      creditData: "",
    }
  },
  computed: {
    ...mapState("UserViews", ["reload"]),
    _columns() {
      return [
        {
          label: "status",
          field: "status_data.name_th",
          // tdClass: "text-right",
          thClass: "text-center",
          sortable: false,
        },
        {
          label: "credit balance",
          field: "credit_balance",
          thClass: "text-center",
          tdClass: "text-right",
          type: "string",
          sortable: false,
        },
        {
          label: "credit amount",
          field: "credit_amount",
          tdClass: "text-right",
          thClass: "text-center",
          sortable: false,
          // type: "number",
        },
        {
          label: "credit total",
          field: "credit_total",
          tdClass: "text-right",
          thClass: "text-center",
          sortable: false,
        },

        {
          label: "Date",
          field: "timestamp",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
        },
      ]
    },
  },
}
</script>
